import * as Types from '@worx.squad/app-sdk/base-types';

import { DocumentNode } from 'graphql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateHolidayMutationVariables = Types.Exact<{
  data:
    | Array<Types.Organization_Holidays_Insert_Input>
    | Types.Organization_Holidays_Insert_Input;
}>;

export type CreateHolidayMutation = {
  __typename?: 'mutation_root';
  insert_organization_holidays?: {
    __typename?: 'organization_holidays_mutation_response';
    affected_rows: number;
  } | null;
};

export type GetHolidaysQueryVariables = Types.Exact<{
  organization_id: Types.Scalars['uuid'];
}>;

export type GetHolidaysQuery = {
  __typename?: 'query_root';
  holidays: Array<{
    __typename?: 'organization_holidays';
    id: any;
    name: string;
    description?: string | null;
    date: any;
  }>;
};

export type UpdateHolidayMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  data: Types.Organization_Holidays_Set_Input;
}>;

export type UpdateHolidayMutation = {
  __typename?: 'mutation_root';
  holiday?: { __typename?: 'organization_holidays'; id: any } | null;
};

export type DeleteHolidayMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;

export type DeleteHolidayMutation = {
  __typename?: 'mutation_root';
  holiday?: { __typename?: 'organization_holidays'; id: any } | null;
};

export const CreateHolidayDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createHoliday' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {
                    kind: 'Name',
                    value: 'organization_holidays_insert_input',
                  },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_organization_holidays' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useCreateHolidayMutation() {
  return Urql.useMutation<
    CreateHolidayMutation,
    CreateHolidayMutationVariables
  >(CreateHolidayDocument);
}
export const GetHolidaysDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getHolidays' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organization_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'holidays' },
            name: { kind: 'Name', value: 'organization_holidays' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'date' },
                      value: { kind: 'EnumValue', value: 'asc_nulls_last' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organization_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useGetHolidaysQuery(
  options: Omit<Urql.UseQueryArgs<GetHolidaysQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetHolidaysQuery, GetHolidaysQueryVariables>({
    query: GetHolidaysDocument,
    ...options,
  });
}
export const UpdateHolidayDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateHoliday' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'organization_holidays_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'holiday' },
            name: { kind: 'Name', value: 'update_organization_holidays_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useUpdateHolidayMutation() {
  return Urql.useMutation<
    UpdateHolidayMutation,
    UpdateHolidayMutationVariables
  >(UpdateHolidayDocument);
}
export const DeleteHolidayDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteHoliday' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'holiday' },
            name: { kind: 'Name', value: 'delete_organization_holidays_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useDeleteHolidayMutation() {
  return Urql.useMutation<
    DeleteHolidayMutation,
    DeleteHolidayMutationVariables
  >(DeleteHolidayDocument);
}
