import * as Types from '@worx.squad/app-sdk/base-types';

import { DocumentNode } from 'graphql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetOrganizationAdminsByOrganizationIdQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['uuid'];
}>;

export type GetOrganizationAdminsByOrganizationIdQuery = {
  __typename?: 'query_root';
  organization_admin: Array<{
    __typename?: 'organization_admin';
    id: any;
    is_owner: boolean;
    userByUserId: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      account?: { __typename?: 'auth_accounts'; email?: any | null } | null;
    };
  }>;
  organization_admin_aggregate: {
    __typename?: 'organization_admin_aggregate';
    aggregate?: {
      __typename?: 'organization_admin_aggregate_fields';
      count: number;
    } | null;
  };
};

export type DeleteOrganizationAdminMutationVariables = Types.Exact<{
  object: Types.DeleteOrgAdminInput;
}>;

export type DeleteOrganizationAdminMutation = {
  __typename?: 'mutation_root';
  deleteOrgAdmin?: {
    __typename?: 'DeleteOrgAdminOutput';
    message?: string | null;
    ok?: boolean | null;
    status?: number | null;
  } | null;
};

export type TransferOrganizationOwnershipMutationVariables = Types.Exact<{
  object: Types.TransferOwnershipInput;
}>;

export type TransferOrganizationOwnershipMutation = {
  __typename?: 'mutation_root';
  transferOwnership?: {
    __typename?: 'TransferOwnershipOutput';
    message?: string | null;
    ok?: boolean | null;
    status?: number | null;
  } | null;
};

export type SaveOrganizationAdminMutationVariables = Types.Exact<{
  object: Types.SaveOrganizationAdminInput;
}>;

export type SaveOrganizationAdminMutation = {
  __typename?: 'mutation_root';
  saveOrganizationAdmin: {
    __typename?: 'SaveOrganizationAdminOutput';
    userId: any;
  };
};

export const GetOrganizationAdminsByOrganizationIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationAdminsByOrganizationId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_admin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organizationId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userByUserId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'display_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'account' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'is_owner' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_admin_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organizationId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'count' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'columns' },
                            value: { kind: 'EnumValue', value: 'id' },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useGetOrganizationAdminsByOrganizationIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GetOrganizationAdminsByOrganizationIdQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    GetOrganizationAdminsByOrganizationIdQuery,
    GetOrganizationAdminsByOrganizationIdQueryVariables
  >({ query: GetOrganizationAdminsByOrganizationIdDocument, ...options });
}
export const DeleteOrganizationAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteOrganizationAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteOrgAdminInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteOrgAdmin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useDeleteOrganizationAdminMutation() {
  return Urql.useMutation<
    DeleteOrganizationAdminMutation,
    DeleteOrganizationAdminMutationVariables
  >(DeleteOrganizationAdminDocument);
}
export const TransferOrganizationOwnershipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'transferOrganizationOwnership' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TransferOwnershipInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transferOwnership' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useTransferOrganizationOwnershipMutation() {
  return Urql.useMutation<
    TransferOrganizationOwnershipMutation,
    TransferOrganizationOwnershipMutationVariables
  >(TransferOrganizationOwnershipDocument);
}
export const SaveOrganizationAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'saveOrganizationAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SaveOrganizationAdminInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saveOrganizationAdmin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useSaveOrganizationAdminMutation() {
  return Urql.useMutation<
    SaveOrganizationAdminMutation,
    SaveOrganizationAdminMutationVariables
  >(SaveOrganizationAdminDocument);
}
