import * as Types from '@worx.squad/app-sdk/base-types';

import { DocumentNode } from 'graphql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetOrganizationDetailsQueryVariables = Types.Exact<{
  organization_id: Types.Scalars['uuid'];
}>;

export type GetOrganizationDetailsQuery = {
  __typename?: 'query_root';
  organization: Array<{
    __typename?: 'organization';
    anniversary_date?: any | null;
    organization_name: string;
    working_days: number;
    working_hours: number;
    holiday_file_id?: any | null;
    logo_file_id?: any | null;
    org_end_time: any;
    org_start_time: any;
    timezone_name: string;
    address?: {
      __typename?: 'address';
      id: any;
      address_line: string;
      country: string;
      pin_code: string;
      state: string;
    } | null;
    organization_employees_aggregate: {
      __typename?: 'organization_employee_aggregate';
      aggregate?: {
        __typename?: 'organization_employee_aggregate_fields';
        count: number;
      } | null;
    };
  }>;
};

export type UpdateAddressDetailsMutationVariables = Types.Exact<{
  data: Types.Address_Set_Input;
  addressID: Types.Scalars['uuid'];
}>;

export type UpdateAddressDetailsMutation = {
  __typename?: 'mutation_root';
  update_address?: {
    __typename?: 'address_mutation_response';
    affected_rows: number;
  } | null;
};

export type UpdateOrganizationDetailsMutationVariables = Types.Exact<{
  data: Types.Organization_Set_Input;
  organizationID: Types.Scalars['uuid'];
}>;

export type UpdateOrganizationDetailsMutation = {
  __typename?: 'mutation_root';
  update_organization?: {
    __typename?: 'organization_mutation_response';
    affected_rows: number;
  } | null;
};

export const GetOrganizationDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organization_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organization_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address_line' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pin_code' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'anniversary_date' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_name' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'working_days' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'working_hours' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'organization_employees_aggregate',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'holiday_file_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logo_file_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'org_end_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'org_start_time' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timezone_name' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useGetOrganizationDetailsQuery(
  options: Omit<
    Urql.UseQueryArgs<GetOrganizationDetailsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    GetOrganizationDetailsQuery,
    GetOrganizationDetailsQueryVariables
  >({ query: GetOrganizationDetailsDocument, ...options });
}
export const UpdateAddressDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateAddressDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'address_set_input' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addressID' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_address' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'addressID' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useUpdateAddressDetailsMutation() {
  return Urql.useMutation<
    UpdateAddressDetailsMutation,
    UpdateAddressDetailsMutationVariables
  >(UpdateAddressDetailsDocument);
}
export const UpdateOrganizationDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateOrganizationDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'organization_set_input' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationID' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_organization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'organizationID' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useUpdateOrganizationDetailsMutation() {
  return Urql.useMutation<
    UpdateOrganizationDetailsMutation,
    UpdateOrganizationDetailsMutationVariables
  >(UpdateOrganizationDetailsDocument);
}
