import * as Types from '@worx.squad/app-sdk/base-types';

import { DocumentNode } from 'graphql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetTimePeriodListQueryVariables = Types.Exact<{
  _ilike?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type GetTimePeriodListQuery = {
  __typename?: 'query_root';
  time_period: Array<{
    __typename?: 'time_period';
    datePeriod: string;
    timePeriod: string;
  }>;
};

export type GetPerformanceReviewTableDataQueryVariables = Types.Exact<{
  _eq?: Types.InputMaybe<Types.Scalars['uuid']>;
  _eq1?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type GetPerformanceReviewTableDataQuery = {
  __typename?: 'query_root';
  performance_review_settings: Array<{
    __typename?: 'performance_review_settings';
    id: any;
    frequency: string;
    year: string;
    instruction: string;
    organization_Id: any;
    startDate?: string | null;
    endDate?: string | null;
    name?: string | null;
    org_goal_share?: string | null;
    copy_previews_performance_goals?: boolean | null;
    self_review?: string | null;
    manager_review?: string | null;
    peer_review?: string | null;
    view_option?: boolean | null;
    performance_review_setting_With_Goals: Array<{
      __typename?: 'performance_review_setting_With_Goals';
      action: string;
      dueDate: any;
      id: any;
      startDate: any;
      timePeriod: string;
      status: string;
      startingDate?: string | null;
      endingDate?: string | null;
      title: string;
      year: string;
      preformance_Id: any;
      viewScoreOption?: string | null;
      performance_review_setting_GoalsLists_aggregate: {
        __typename?: 'performance_review_setting_GoalsList_aggregate';
        aggregate?: {
          __typename?: 'performance_review_setting_GoalsList_aggregate_fields';
          count: number;
        } | null;
      };
    }>;
    performanceReviewStatuses_aggregate: {
      __typename?: 'performanceReviewStatus_aggregate';
      aggregate?: {
        __typename?: 'performanceReviewStatus_aggregate_fields';
        count: number;
      } | null;
    };
    organization: {
      __typename?: 'organization';
      organization_employees_aggregate: {
        __typename?: 'organization_employee_aggregate';
        aggregate?: {
          __typename?: 'organization_employee_aggregate_fields';
          count: number;
        } | null;
      };
    };
    performance_rating_scales: Array<{
      __typename?: 'performance_rating_scale';
      id: any;
      name?: string | null;
      rating?: number | null;
    }>;
  }>;
};

export type CreatePerformanceReviewMutationVariables = Types.Exact<{
  object: Types.PerformanceReviewInput;
}>;

export type CreatePerformanceReviewMutation = {
  __typename?: 'mutation_root';
  createPerformanceReview?: {
    __typename?: 'PerformanceReviewOutput';
    id: any;
  } | null;
};

export type UpdatePerformanceReviewMutationVariables = Types.Exact<{
  object: Types.UpdatePerofrmanceReviewInput;
}>;

export type UpdatePerformanceReviewMutation = {
  __typename?: 'mutation_root';
  updatePerformanceReview?: {
    __typename?: 'UpdatePerofrmanceReviewOutput';
    id: any;
  } | null;
};

export type FinancialYearValueQueryVariables = Types.Exact<{
  _eq: Types.Scalars['uuid'];
  todaysDate: Types.Scalars['String'];
}>;

export type FinancialYearValueQuery = {
  __typename?: 'query_root';
  performance_review_settings: Array<{
    __typename?: 'performance_review_settings';
    startDate?: string | null;
    endDate?: string | null;
    year: string;
  }>;
};

export type GetGoalNoPerRolesMutationVariables = Types.Exact<{
  object: Types.GoalCountPerRolesInput;
}>;

export type GetGoalNoPerRolesMutation = {
  __typename?: 'mutation_root';
  getGoalNoPerRoles?: {
    __typename?: 'GoalCountPerRolesOutput';
    response: Array<{
      __typename?: 'GoalDetailPerRoles';
      roleTitle?: string | null;
      count?: number | null;
      managerId?: any | null;
      roleId?: any | null;
    } | null>;
  } | null;
};

export type PerformanceReviewNotificationForManagerMutationVariables =
  Types.Exact<{
    object: Types.PerformanceReviewNotifyInput;
  }>;

export type PerformanceReviewNotificationForManagerMutation = {
  __typename?: 'mutation_root';
  performanceReviewNotification?: {
    __typename?: 'PerformanceReviewNotifyOutput';
    response: string;
  } | null;
};

export type Get_ListingYear_Org_AdminQueryVariables = Types.Exact<{
  _eq: Types.Scalars['uuid'];
}>;

export type Get_ListingYear_Org_AdminQuery = {
  __typename?: 'query_root';
  performance_review_settings: Array<{
    __typename?: 'performance_review_settings';
    year: string;
  }>;
};

export type Get_TimePeriod_ListMutationVariables = Types.Exact<{
  object: Types.TimePeriodListInput;
}>;

export type Get_TimePeriod_ListMutation = {
  __typename?: 'mutation_root';
  getTimePeriodList?: {
    __typename?: 'TimePeriodListOutput';
    response: Array<{
      __typename?: 'timePeriodRespList';
      timePeriod?: string | null;
      year?: string | null;
    } | null>;
  } | null;
};

export type CheckingPerformanceReviewStatusQueryVariables = Types.Exact<{
  _eq1: Types.Scalars['uuid'];
  _eq: Types.Scalars['uuid'];
}>;

export type CheckingPerformanceReviewStatusQuery = {
  __typename?: 'query_root';
  performanceReviewStatus_aggregate: {
    __typename?: 'performanceReviewStatus_aggregate';
    aggregate?: {
      __typename?: 'performanceReviewStatus_aggregate_fields';
      count: number;
    } | null;
  };
};

export type CheckGoalCountMutationVariables = Types.Exact<{
  object: Types.GoalCountPerTimeperiodInput;
}>;

export type CheckGoalCountMutation = {
  __typename?: 'mutation_root';
  checkGoalCount?: {
    __typename?: 'goalCountPerTimeperiodOutput';
    response: string;
  } | null;
};

export type LoadPerformanceReviewMutationVariables = Types.Exact<{
  object: Types.LoadPerformanceReviewInput;
}>;

export type LoadPerformanceReviewMutation = {
  __typename?: 'mutation_root';
  loadPerformanceReview?: {
    __typename?: 'LoadPerformanceReviewOutput';
    response?: Array<{
      __typename?: 'List_Of_Performance_Review';
      id?: any | null;
      name?: string | null;
      duration?: string | null;
      year?: string | null;
      status?: string | null;
      period?: Array<{
        __typename?: 'List_Of_TimePeriod';
        id?: any | null;
        title?: any | null;
        timePeriod?: string | null;
        startDate?: string | null;
        dueDate?: string | null;
        startingDate?: string | null;
        endingDate?: string | null;
        status?: string | null;
        action?: string | null;
        goalDetails?: number | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export const GetTimePeriodListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTimePeriodList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: '_ilike' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'time_period' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timePeriod' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_ilike' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_ilike' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'datePeriod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timePeriod' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useGetTimePeriodListQuery(
  options?: Omit<Urql.UseQueryArgs<GetTimePeriodListQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetTimePeriodListQuery, GetTimePeriodListQueryVariables>(
    { query: GetTimePeriodListDocument, ...options },
  );
}
export const GetPerformanceReviewTableDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPerformanceReviewTableData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_settings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'year' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                { kind: 'Field', name: { kind: 'Name', value: 'instruction' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization_Id' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'org_goal_share' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'copy_previews_performance_goals',
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'self_review' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manager_review' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'peer_review' } },
                { kind: 'Field', name: { kind: 'Name', value: 'view_option' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'performance_review_setting_With_Goals',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'startDate' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'action' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dueDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timePeriod' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startingDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endingDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preformance_Id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'viewScoreOption' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value:
                            'performance_review_setting_GoalsLists_aggregate',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aggregate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'performanceReviewStatuses_aggregate',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'organization_employees_aggregate',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aggregate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'performance_rating_scales' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rating' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useGetPerformanceReviewTableDataQuery(
  options?: Omit<
    Urql.UseQueryArgs<GetPerformanceReviewTableDataQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    GetPerformanceReviewTableDataQuery,
    GetPerformanceReviewTableDataQueryVariables
  >({ query: GetPerformanceReviewTableDataDocument, ...options });
}
export const CreatePerformanceReviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createPerformanceReview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PerformanceReviewInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPerformanceReview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useCreatePerformanceReviewMutation() {
  return Urql.useMutation<
    CreatePerformanceReviewMutation,
    CreatePerformanceReviewMutationVariables
  >(CreatePerformanceReviewDocument);
}
export const UpdatePerformanceReviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePerformanceReview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdatePerofrmanceReviewInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePerformanceReview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useUpdatePerformanceReviewMutation() {
  return Urql.useMutation<
    UpdatePerformanceReviewMutation,
    UpdatePerformanceReviewMutationVariables
  >(UpdatePerformanceReviewDocument);
}
export const FinancialYearValueDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'financialYearValue' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'todaysDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_settings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_review_setting_With_Goals',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: {
                              kind: 'Name',
                              value: 'performance_review_setting',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'organization_Id',
                                  },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: '_eq' },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'startingDate' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_lte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'todaysDate' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'endingDate' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_gte' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'todaysDate' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'year' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useFinancialYearValueQuery(
  options: Omit<Urql.UseQueryArgs<FinancialYearValueQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    FinancialYearValueQuery,
    FinancialYearValueQueryVariables
  >({ query: FinancialYearValueDocument, ...options });
}
export const GetGoalNoPerRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'getGoalNoPerRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GoalCountPerRolesInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getGoalNoPerRoles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'roleTitle' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'managerId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'roleId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useGetGoalNoPerRolesMutation() {
  return Urql.useMutation<
    GetGoalNoPerRolesMutation,
    GetGoalNoPerRolesMutationVariables
  >(GetGoalNoPerRolesDocument);
}
export const PerformanceReviewNotificationForManagerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'performanceReviewNotificationForManager' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PerformanceReviewNotifyInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performanceReviewNotification' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'response' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function usePerformanceReviewNotificationForManagerMutation() {
  return Urql.useMutation<
    PerformanceReviewNotificationForManagerMutation,
    PerformanceReviewNotificationForManagerMutationVariables
  >(PerformanceReviewNotificationForManagerDocument);
}
export const Get_ListingYear_Org_AdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'get_listingYear_Org_Admin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performance_review_settings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'organization_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'year' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useGet_ListingYear_Org_AdminQuery(
  options: Omit<
    Urql.UseQueryArgs<Get_ListingYear_Org_AdminQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    Get_ListingYear_Org_AdminQuery,
    Get_ListingYear_Org_AdminQueryVariables
  >({ query: Get_ListingYear_Org_AdminDocument, ...options });
}
export const Get_TimePeriod_ListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'get_TimePeriod_List' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TimePeriodListInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTimePeriodList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timePeriod' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useGet_TimePeriod_ListMutation() {
  return Urql.useMutation<
    Get_TimePeriod_ListMutation,
    Get_TimePeriod_ListMutationVariables
  >(Get_TimePeriod_ListDocument);
}
export const CheckingPerformanceReviewStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkingPerformanceReviewStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_eq' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'performanceReviewStatus_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'performance_Id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: '_eq1' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'performance_review_setting',
                      },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'organization_Id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: '_eq' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useCheckingPerformanceReviewStatusQuery(
  options: Omit<
    Urql.UseQueryArgs<CheckingPerformanceReviewStatusQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    CheckingPerformanceReviewStatusQuery,
    CheckingPerformanceReviewStatusQueryVariables
  >({ query: CheckingPerformanceReviewStatusDocument, ...options });
}
export const CheckGoalCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'checkGoalCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'goalCountPerTimeperiodInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkGoalCount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'response' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useCheckGoalCountMutation() {
  return Urql.useMutation<
    CheckGoalCountMutation,
    CheckGoalCountMutationVariables
  >(CheckGoalCountDocument);
}
export const LoadPerformanceReviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'loadPerformanceReview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoadPerformanceReviewInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loadPerformanceReview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'duration' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'period' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'timePeriod' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dueDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startingDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endingDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'action' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'goalDetails' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useLoadPerformanceReviewMutation() {
  return Urql.useMutation<
    LoadPerformanceReviewMutation,
    LoadPerformanceReviewMutationVariables
  >(LoadPerformanceReviewDocument);
}
