import * as Types from '@worx.squad/app-sdk/base-types';

import { DocumentNode } from 'graphql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ContactUsMutationVariables = Types.Exact<{
  object?: Types.InputMaybe<Types.ContactUsInput>;
}>;

export type ContactUsMutation = {
  __typename?: 'mutation_root';
  contactUs: { __typename?: 'ContactUsOutput'; success: string };
};

export const ContactUsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'contactUs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ContactUsInput' },
          },
          defaultValue: {
            kind: 'ObjectValue',
            fields: [
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'StringValue', value: '', block: false },
              },
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'firstName' },
                value: { kind: 'StringValue', value: '', block: false },
              },
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'lastName' },
                value: { kind: 'StringValue', value: '', block: false },
              },
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'phone' },
                value: { kind: 'StringValue', value: '', block: false },
              },
            ],
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactUs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useContactUsMutation() {
  return Urql.useMutation<ContactUsMutation, ContactUsMutationVariables>(
    ContactUsDocument,
  );
}
