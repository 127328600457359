import * as Types from '@worx.squad/app-sdk/base-types';

import { DocumentNode } from 'graphql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateLeaveCycleMutationVariables = Types.Exact<{
  org_id: Types.Scalars['uuid'];
  starting_date: Types.Scalars['String'];
}>;

export type CreateLeaveCycleMutation = {
  __typename?: 'mutation_root';
  createLeaveCycle?: {
    __typename?: 'LeaveCycleOutput';
    leave_cycle_id: string;
  } | null;
};

export type GetLeaveCycleSubscriptionVariables = Types.Exact<{
  org_id: Types.Scalars['uuid'];
}>;

export type GetLeaveCycleSubscription = {
  __typename?: 'subscription_root';
  organization_leave_cycles: Array<{
    __typename: 'organization_leave_cycles';
    starting_date?: string | null;
    ending_date?: string | null;
  }>;
};

export type AddNewLeaveTypeMutationVariables = Types.Exact<{
  object: Types.AddNewLeaveTypeInput;
}>;

export type AddNewLeaveTypeMutation = {
  __typename?: 'mutation_root';
  addNewLeaveType?: {
    __typename?: 'AddNewLeaveTypeOutput';
    new_leave_type_id?: string | null;
  } | null;
};

export type UpdateLeaveTypeByIdMutationVariables = Types.Exact<{
  object: Types.EditLeaveTypeInput;
}>;

export type UpdateLeaveTypeByIdMutation = {
  __typename?: 'mutation_root';
  editLeaveType?: {
    __typename?: 'EditLeaveTypeOutput';
    leave_type_id: string;
  } | null;
};

export type DeleteOrgActiveLeaveTypeMutationVariables = Types.Exact<{
  object: Types.EditLeaveTypeInput;
}>;

export type DeleteOrgActiveLeaveTypeMutation = {
  __typename?: 'mutation_root';
  editLeaveType?: {
    __typename?: 'EditLeaveTypeOutput';
    leave_type_id: string;
  } | null;
};

export type ApplyForLeaveMutationVariables = Types.Exact<{
  org_id: Types.Scalars['String'];
  leave_type_id: Types.Scalars['String'];
  leave_start_date?: Types.InputMaybe<Types.Scalars['String']>;
  leave_end_date?: Types.InputMaybe<Types.Scalars['String']>;
  leave_dates:
    | Array<Types.InputMaybe<Types.Scalars['String']>>
    | Types.InputMaybe<Types.Scalars['String']>;
}>;

export type ApplyForLeaveMutation = {
  __typename?: 'mutation_root';
  submitLeave: {
    __typename?: 'SubmitLeaveOutput';
    new_employee_leave_id: string;
  };
};

export type GetOrganizationLeaveCycleByOrgIdAndStartingDateSubSubscriptionVariables =
  Types.Exact<{
    orgId: Types.Scalars['uuid'];
    starting_date: Types.Scalars['String'];
  }>;

export type GetOrganizationLeaveCycleByOrgIdAndStartingDateSubSubscription = {
  __typename?: 'subscription_root';
  organization_leave_cycles: Array<{
    __typename?: 'organization_leave_cycles';
    id: any;
  }>;
};

export type GetEmployeeLeaveApplicationByIdSubscriptionVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;

export type GetEmployeeLeaveApplicationByIdSubscription = {
  __typename?: 'subscription_root';
  organization_user_leave_application_by_pk?: {
    __typename?: 'organization_user_leave_application';
    id: any;
    created_at: any;
    updated_at: any;
    org_id: any;
    org_user_id: any;
    leave_type: string;
    is_approved: boolean;
    leave_start_date?: string | null;
    leave_end_date?: string | null;
    leave_application_status: Types.Enum_Leave_Status_Type_Enum;
    leave_type_id?: any | null;
    leaveDates: Array<{
      __typename?: 'organization_user_leave_dates';
      id: any;
      created_at: any;
      updated_at: any;
      leave_application_id: any;
      date: string;
    }>;
    user_leave_data?: {
      __typename?: 'organization_user_leave_data';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      org_user_id: any;
      user_role: string;
      leave_type?: string | null;
      leave_type_id?: any | null;
      earned_leaves_amount?: any | null;
    } | null;
  } | null;
};

export type GetOrgActiveLeaveTypesSubscriptionVariables = Types.Exact<{
  org_id: Types.Scalars['uuid'];
}>;

export type GetOrgActiveLeaveTypesSubscription = {
  __typename?: 'subscription_root';
  organization_active_leave_types_aggregate: {
    __typename?: 'organization_active_leave_types_aggregate';
    nodes: Array<{
      __typename?: 'organization_active_leave_types';
      id: any;
      annual_eligibility: number;
      leave_type: string;
      monthly_earning_amount?: any | null;
      annual_carry_forward_amount?: number | null;
      limit_of_days_at_once?: number | null;
    }>;
    aggregate?: {
      __typename?: 'organization_active_leave_types_aggregate_fields';
      sum?: {
        __typename?: 'organization_active_leave_types_sum_fields';
        annual_eligibility?: number | null;
      } | null;
    } | null;
  };
};

export type GetLeaveTypeByIdSubscriptionVariables = Types.Exact<{
  edit_id: Types.Scalars['uuid'];
}>;

export type GetLeaveTypeByIdSubscription = {
  __typename?: 'subscription_root';
  organization_active_leave_types_by_pk?: {
    __typename?: 'organization_active_leave_types';
    annual_carry_forward_amount?: number | null;
    leave_type: string;
    annual_eligibility: number;
    monthly_earning_amount?: any | null;
    limit_of_days_at_once?: number | null;
    will_carry_forward: boolean;
    will_be_earned_monthly: boolean;
  } | null;
};

export type GetUserLeaveDataSubscriptionVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetUserLeaveDataSubscription = {
  __typename?: 'subscription_root';
  organization_user_leave_data: Array<{
    __typename?: 'organization_user_leave_data';
    used_leaves?: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
    }> | null;
    rejected_leaves?: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
    }> | null;
    pending_leaves?: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
    }> | null;
  }>;
};

export type GetLeaveDataOfOrganizationByLeaveIdSubscriptionVariables =
  Types.Exact<{
    leave_cycle_id: Types.Scalars['uuid'];
  }>;

export type GetLeaveDataOfOrganizationByLeaveIdSubscription = {
  __typename?: 'subscription_root';
  organization_user_leave_data: Array<{
    __typename?: 'organization_user_leave_data';
    id: any;
    created_at: any;
    updated_at: any;
    org_id: any;
    org_user_id: any;
    user_role: string;
    leave_type?: string | null;
    leave_type_id?: any | null;
    earned_leaves_amount?: any | null;
    used_leave_amount: number;
    used_leaves?: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      org_user_id: any;
      leave_type: string;
      is_approved: boolean;
      leave_start_date?: string | null;
      leave_end_date?: string | null;
      leave_application_status: Types.Enum_Leave_Status_Type_Enum;
      leave_type_id?: any | null;
      leave_amount_in_days: number;
      uploaded_files_for_leave: Array<{
        __typename?: 'organization_user_leave_files';
        id: any;
        storage_file_id: any;
        file_url: string;
      }>;
    }> | null;
    rejected_leaves?: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      org_user_id: any;
      leave_type: string;
      is_approved: boolean;
      leave_start_date?: string | null;
      leave_end_date?: string | null;
      leave_amount_in_days: number;
      leave_application_status: Types.Enum_Leave_Status_Type_Enum;
      leave_type_id?: any | null;
      uploaded_files_for_leave: Array<{
        __typename?: 'organization_user_leave_files';
        id: any;
        storage_file_id: any;
        file_url: string;
      }>;
    }> | null;
    pending_leaves?: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      org_user_id: any;
      leave_type: string;
      is_approved: boolean;
      leave_start_date?: string | null;
      leave_end_date?: string | null;
      leave_amount_in_days: number;
      leave_application_status: Types.Enum_Leave_Status_Type_Enum;
      leave_type_id?: any | null;
      uploaded_files_for_leave: Array<{
        __typename?: 'organization_user_leave_files';
        id: any;
        storage_file_id: any;
        file_url: string;
      }>;
    }> | null;
    leave_type_data?: {
      __typename?: 'organization_active_leave_types';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      leave_type: string;
      will_carry_forward: boolean;
      will_be_earned_monthly: boolean;
      monthly_earning_amount?: any | null;
      annual_eligibility: number;
      annual_carry_forward_amount?: number | null;
      followed_leave_cycle?: any | null;
      organization_leave_cycle?: {
        __typename?: 'organization_leave_cycles';
        id: any;
        starting_date?: string | null;
        ending_date?: string | null;
        current_term_years?: string | null;
      } | null;
    } | null;
    leave_applications: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      org_user_id: any;
      leave_type: string;
      is_approved: boolean;
      leave_start_date?: string | null;
      leave_end_date?: string | null;
      leave_application_status: Types.Enum_Leave_Status_Type_Enum;
      leave_type_id?: any | null;
      leave_amount_in_days: number;
    }>;
  }>;
};

export type GetOrganizationLeaveDataQueryVariables = Types.Exact<{
  startDate: Types.Scalars['String'];
  endDate: Types.Scalars['String'];
  organizationId: Types.Scalars['uuid'];
}>;

export type GetOrganizationLeaveDataQuery = {
  __typename?: 'query_root';
  organization_user_leave_dates: Array<{
    __typename?: 'organization_user_leave_dates';
    date: string;
    leave_application?: {
      __typename?: 'organization_user_leave_application';
      leave_type: string;
      leave_application_status: Types.Enum_Leave_Status_Type_Enum;
      leave_reason?: string | null;
      user: {
        __typename?: 'users';
        first_name?: string | null;
        last_name?: string | null;
      };
    } | null;
  }>;
};

export const CreateLeaveCycleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createLeaveCycle' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'starting_date' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createLeaveCycle' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'starting_date' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'starting_date' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_cycle_id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useCreateLeaveCycleMutation() {
  return Urql.useMutation<
    CreateLeaveCycleMutation,
    CreateLeaveCycleMutationVariables
  >(CreateLeaveCycleDocument);
}
export const GetLeaveCycleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getLeaveCycle' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_leave_cycles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'org_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'starting_date' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'ending_date' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useGetLeaveCycleSubscription<TData = GetLeaveCycleSubscription>(
  options: Omit<
    Urql.UseSubscriptionArgs<GetLeaveCycleSubscriptionVariables>,
    'query'
  > = {},
  handler?: Urql.SubscriptionHandler<GetLeaveCycleSubscription, TData>,
) {
  return Urql.useSubscription<
    GetLeaveCycleSubscription,
    TData,
    GetLeaveCycleSubscriptionVariables
  >({ query: GetLeaveCycleDocument, ...options }, handler);
}
export const AddNewLeaveTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addNewLeaveType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddNewLeaveTypeInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addNewLeaveType' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'new_leave_type_id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useAddNewLeaveTypeMutation() {
  return Urql.useMutation<
    AddNewLeaveTypeMutation,
    AddNewLeaveTypeMutationVariables
  >(AddNewLeaveTypeDocument);
}
export const UpdateLeaveTypeByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateLeaveTypeById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EditLeaveTypeInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editLeaveType' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_type_id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useUpdateLeaveTypeByIdMutation() {
  return Urql.useMutation<
    UpdateLeaveTypeByIdMutation,
    UpdateLeaveTypeByIdMutationVariables
  >(UpdateLeaveTypeByIdDocument);
}
export const DeleteOrgActiveLeaveTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteOrgActiveLeaveType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EditLeaveTypeInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editLeaveType' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_type_id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useDeleteOrgActiveLeaveTypeMutation() {
  return Urql.useMutation<
    DeleteOrgActiveLeaveTypeMutation,
    DeleteOrgActiveLeaveTypeMutationVariables
  >(DeleteOrgActiveLeaveTypeDocument);
}
export const ApplyForLeaveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'applyForLeave' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_id' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'leave_type_id' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'leave_start_date' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'leave_end_date' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'leave_dates' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitLeave' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_type_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'leave_type_id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_start_date' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'leave_start_date' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_end_date' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'leave_end_date' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_dates' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'leave_dates' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'new_employee_leave_id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useApplyForLeaveMutation() {
  return Urql.useMutation<
    ApplyForLeaveMutation,
    ApplyForLeaveMutationVariables
  >(ApplyForLeaveDocument);
}
export const GetOrganizationLeaveCycleByOrgIdAndStartingDateSubDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: {
        kind: 'Name',
        value: 'getOrganizationLeaveCycleByOrgIdAndStartingDateSub',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'starting_date' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_leave_cycles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'starting_date' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'starting_date' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useGetOrganizationLeaveCycleByOrgIdAndStartingDateSubSubscription<
  TData = GetOrganizationLeaveCycleByOrgIdAndStartingDateSubSubscription,
>(
  options: Omit<
    Urql.UseSubscriptionArgs<GetOrganizationLeaveCycleByOrgIdAndStartingDateSubSubscriptionVariables>,
    'query'
  > = {},
  handler?: Urql.SubscriptionHandler<
    GetOrganizationLeaveCycleByOrgIdAndStartingDateSubSubscription,
    TData
  >,
) {
  return Urql.useSubscription<
    GetOrganizationLeaveCycleByOrgIdAndStartingDateSubSubscription,
    TData,
    GetOrganizationLeaveCycleByOrgIdAndStartingDateSubSubscriptionVariables
  >(
    {
      query: GetOrganizationLeaveCycleByOrgIdAndStartingDateSubDocument,
      ...options,
    },
    handler,
  );
}
export const GetEmployeeLeaveApplicationByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getEmployeeLeaveApplicationById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'organization_user_leave_application_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'org_user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'leave_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_approved' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_start_date' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_end_date' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_application_status' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_type_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leaveDates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_application_id' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user_leave_data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_role' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'earned_leaves_amount' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useGetEmployeeLeaveApplicationByIdSubscription<
  TData = GetEmployeeLeaveApplicationByIdSubscription,
>(
  options: Omit<
    Urql.UseSubscriptionArgs<GetEmployeeLeaveApplicationByIdSubscriptionVariables>,
    'query'
  > = {},
  handler?: Urql.SubscriptionHandler<
    GetEmployeeLeaveApplicationByIdSubscription,
    TData
  >,
) {
  return Urql.useSubscription<
    GetEmployeeLeaveApplicationByIdSubscription,
    TData,
    GetEmployeeLeaveApplicationByIdSubscriptionVariables
  >({ query: GetEmployeeLeaveApplicationByIdDocument, ...options }, handler);
}
export const GetOrgActiveLeaveTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'GetOrgActiveLeaveTypes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'org_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'organization_active_leave_types_aggregate',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'org_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'org_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_type' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'annual_eligibility' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'monthly_earning_amount' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'annual_carry_forward_amount',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'limit_of_days_at_once' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sum' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'annual_eligibility',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useGetOrgActiveLeaveTypesSubscription<
  TData = GetOrgActiveLeaveTypesSubscription,
>(
  options: Omit<
    Urql.UseSubscriptionArgs<GetOrgActiveLeaveTypesSubscriptionVariables>,
    'query'
  > = {},
  handler?: Urql.SubscriptionHandler<GetOrgActiveLeaveTypesSubscription, TData>,
) {
  return Urql.useSubscription<
    GetOrgActiveLeaveTypesSubscription,
    TData,
    GetOrgActiveLeaveTypesSubscriptionVariables
  >({ query: GetOrgActiveLeaveTypesDocument, ...options }, handler);
}
export const GetLeaveTypeByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'GetLeaveTypeById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'edit_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'organization_active_leave_types_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'edit_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'annual_carry_forward_amount' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'leave_type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'annual_eligibility' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'monthly_earning_amount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'limit_of_days_at_once' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'will_carry_forward' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'will_be_earned_monthly' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useGetLeaveTypeByIdSubscription<
  TData = GetLeaveTypeByIdSubscription,
>(
  options: Omit<
    Urql.UseSubscriptionArgs<GetLeaveTypeByIdSubscriptionVariables>,
    'query'
  > = {},
  handler?: Urql.SubscriptionHandler<GetLeaveTypeByIdSubscription, TData>,
) {
  return Urql.useSubscription<
    GetLeaveTypeByIdSubscription,
    TData,
    GetLeaveTypeByIdSubscriptionVariables
  >({ query: GetLeaveTypeByIdDocument, ...options }, handler);
}
export const GetUserLeaveDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getUserLeaveData' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user_leave_data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'used_leaves' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rejected_leaves' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pending_leaves' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useGetUserLeaveDataSubscription<
  TData = GetUserLeaveDataSubscription,
>(
  options: Omit<
    Urql.UseSubscriptionArgs<GetUserLeaveDataSubscriptionVariables>,
    'query'
  > = {},
  handler?: Urql.SubscriptionHandler<GetUserLeaveDataSubscription, TData>,
) {
  return Urql.useSubscription<
    GetUserLeaveDataSubscription,
    TData,
    GetUserLeaveDataSubscriptionVariables
  >({ query: GetUserLeaveDataDocument, ...options }, handler);
}
export const GetLeaveDataOfOrganizationByLeaveIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getLeaveDataOfOrganizationByLeaveId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'leave_cycle_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user_leave_data' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_cycle_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'leave_cycle_id' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'org_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'org_user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'leave_type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_type_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'earned_leaves_amount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'used_leave_amount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'used_leaves' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_approved' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_start_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_end_date' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'leave_application_status',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_amount_in_days' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'uploaded_files_for_leave',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rejected_leaves' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_approved' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_start_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_end_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_amount_in_days' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'leave_application_status',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type_id' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'uploaded_files_for_leave',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pending_leaves' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_approved' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_start_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_end_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_amount_in_days' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'leave_application_status',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type_id' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'uploaded_files_for_leave',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storage_file_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file_url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_type_data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'will_carry_forward' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'will_be_earned_monthly' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'monthly_earning_amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'annual_eligibility' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'annual_carry_forward_amount',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'followed_leave_cycle' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'organization_leave_cycle',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'starting_date' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ending_date' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'current_term_years',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_applications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'org_user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_approved' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_start_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_end_date' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'leave_application_status',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_amount_in_days' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useGetLeaveDataOfOrganizationByLeaveIdSubscription<
  TData = GetLeaveDataOfOrganizationByLeaveIdSubscription,
>(
  options: Omit<
    Urql.UseSubscriptionArgs<GetLeaveDataOfOrganizationByLeaveIdSubscriptionVariables>,
    'query'
  > = {},
  handler?: Urql.SubscriptionHandler<
    GetLeaveDataOfOrganizationByLeaveIdSubscription,
    TData
  >,
) {
  return Urql.useSubscription<
    GetLeaveDataOfOrganizationByLeaveIdSubscription,
    TData,
    GetLeaveDataOfOrganizationByLeaveIdSubscriptionVariables
  >(
    { query: GetLeaveDataOfOrganizationByLeaveIdDocument, ...options },
    handler,
  );
}
export const GetOrganizationLeaveDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationLeaveData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization_user_leave_dates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'date' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_gte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_lte' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'leave_application' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'org_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'organizationId',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leave_application' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_type' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'leave_application_status',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leave_reason' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'first_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'last_name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

export function useGetOrganizationLeaveDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GetOrganizationLeaveDataQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    GetOrganizationLeaveDataQuery,
    GetOrganizationLeaveDataQueryVariables
  >({ query: GetOrganizationLeaveDataDocument, ...options });
}
